<template>
<div class="row tgju-widgets-row dashboard-widget-profile dashboard-widget-profile-edit widget-border-fix widget-padding" style="padding-top: 0 !important;">
    <div class="tgju-widgets-block col-12">
        <div class="tgju-widget light">
            <div class="tgju-widget-title dot m-0 border-0">
                <h3>حساب کاربری</h3>
            </div>
            <div class="tgju-widget-content full-padding">
                <form @submit.prevent="">
                    <div class="form-line-title mb-3">
                        <div class="form-line-title-block">
                            <i class="uil uil-user" aria-hidden="true"></i>
                            <span class="title">اطلاعات فردی</span>
                        </div>
                    </div>
                    <div class="form-row pading-content-row">
                        <div class="form-group col-md-4">
                            <label for="name">نام</label>
                            <input type="text" id="name" class="widget-input input-no-bg input-lg input-set-border w-100" v-model="profile.firstname" :maxlength="max">
                        </div>
                        <div class="form-group col-md-4">
                            <label for="family">نام خانوادگی</label>
                            <input type="text" id="family" class="widget-input input-no-bg input-lg input-set-border w-100" v-model="profile.lastname" :maxlength="max">
                        </div>
                        <div class="form-group col-md-4">
                            <label for="birthdate">تاریخ تولد </label>
                            <data-picker id="birthdate" v-model="birthdate"></data-picker>
                        </div>
                        <div class="form-group col-12 col-md-12">
                            <label for="bio">درباره من </label>
                            <TextareaAutosize class="widget-textarea textarea-min-h120 textarea-no-bg textarea-no-bg textarea-set-border" id="bio" placeholder="متن درباره من ..." v-model="profile.opts.bio" :min-height="30" :max-height="350" />
                        </div>
                    </div>
                    <div class="form-line-title mb-4 mt-4">
                        <div class="form-line-title-block">
                            <i class="uil uil-user-location" aria-hidden="true"></i>
                            <span class="title">آدرس ها</span>
                        </div>
                    </div>
                    <div class="form-group pading-content-row">
                        <label for="inputAddress">آدرس پستی</label>
                        <input type="text" id="inputAddress" class="widget-input input-no-bg input-lg input-set-border w-100" v-model="profile.opts.address">
                    </div>
                    <div class="form-row pading-content-row">
                        <div class="form-group col-md-4">
                            <label for="inputState">استان</label>
                            <select id="inputState" class="form-control t-widget-select widget-select-lg select-set-border" v-model="profile.opts.country" @change="getProvinces()">
                                <option v-for="(value, key) in countries" :key="key" :value="key"> {{ value }} </option>
                            </select>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputCity">شهر</label>
                            <select id="inputCity" class="form-control t-widget-select widget-select-lg select-set-border" v-model="profile.opts.province">
                                <option v-for="(value, key) in provinces" :key="key" :value="key"> {{ value }} </option>
                            </select>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputZip">کد پستی</label>
                            <input type="text" id="inputZip" class="widget-input input-no-bg input-lg input-set-border w-100" v-model="profile.opts.postalCode">
                        </div>
                    </div>
                    <div class="form-line-title mb-4 mt-4">
                        <div class="form-line-title-block">
                            <i class="uil uil-envelope-share" aria-hidden="true"></i>
                            <span class="title">راه‌های ارتباطی</span>
                        </div>
                    </div>
                    <div class="form-group pading-content-row">
                        <label for="mail"><i class="uil uil-envelope"></i> ایمیل</label>
                        <input type="text" id="mail" placeholder="you@mail.com" class="widget-input input-no-bg input-lg input-set-border w-100 text-left dir-ltr" v-model="profile.email" disabled>
                    </div>
                    <div class="form-group pading-content-row">
                        <label for="mobile"><i class="uil uil-mobile-android"></i> تلفن همراه</label>
                        <input type="text" id="mobile" placeholder="0900000000" class="widget-input input-no-bg input-lg input-set-border w-100 text-left dir-ltr" v-model="profile.mobile" disabled>
                    </div>
                    
                    <div class="form-group pading-content-row">
                        <label for="facebook"><i class="uil uil-facebook"></i> فیسبوک</label>
                        <input type="text" id="facebook" placeholder="https://facebook.com/..." class="widget-input input-no-bg input-lg input-set-border w-100 text-left dir-ltr" v-model="profile.opts.social.facebook">
                    </div>
                    <div class="form-group pading-content-row">
                        <label for="twitter"><i class="uil uil-twitter"></i> توییتر</label>
                        <input type="text" id="twitter" placeholder="https://twitter.com/..." class="widget-input input-no-bg input-lg input-set-border w-100 text-left dir-ltr" v-model="profile.opts.social.twitter">
                    </div>
                    <div class="form-group pading-content-row">
                        <label for="linkedin"><i class="uil uil-linkedin"></i> لینکدین </label>
                        <input type="text" id="linkedin" placeholder="https://linkedin.com/..." class="widget-input input-no-bg input-lg input-set-border w-100 text-left dir-ltr" v-model="profile.opts.social.linkedin">
                    </div>
                    <div class="form-group pading-content-row">
                        <label for="instagram"><i class="uil uil-instagram-alt"></i> اینستاگرام</label>
                        <input type="text" id="instagram" placeholder="https://instagram.com/..." class="widget-input input-no-bg input-lg input-set-border w-100 text-left dir-ltr" v-model="profile.opts.social.instagram">
                    </div>
                    <div class="form-group pading-content-row">
                        <label for="whatsapp"><i class="uil uil-whatsapp-alt"></i> واتس اپ</label>
                        <input type="text" id="whatsapp" placeholder="https://whatsapp.com/..." class="widget-input input-no-bg input-lg input-set-border w-100 text-left dir-ltr" v-model="profile.opts.social.whatsapp">
                    </div>
                    <div class="form-group pading-content-row">
                        <label for="telegram"><i class="uil uil-telegram"></i> تلگرام</label>
                        <input type="text" id="telegram" placeholder="https://telegram.me/..." class="widget-input input-no-bg input-lg input-set-border w-100 text-left dir-ltr" v-model="profile.opts.social.telegram">
                    </div>
                    <div class="form-group pading-content-row">
                        <label for="discord"><i class="uil uil-discord"></i> دیسکورد</label>
                        <input type="text" id="discord" placeholder="https://discord.com/..." class="widget-input input-no-bg input-lg input-set-border w-100 text-left dir-ltr" v-model="profile.opts.social.discord">
                    </div>
                    <!-- <div class="form-group pading-content-row">

<label for="google"><i class="uil uil-google"></i> گوگل پلاس</label>

<input type="text" id="google" placeholder="https://google.com/..." class="widget-input input-no-bg input-lg input-set-border w-100 text-left dir-ltr" v-model="profile.opts.social.googlePlus">

</div> -->

                    <!-- <div class="form-group pading-content-row mb-3">

<div class="form-row">

<div class="col-12">

<input type="checkbox" class="custom-control-input todo-check-show" id="customControlValidation1" name="customControlValidation1" checked disabled>

<label class="custom-control-label" for="customControlValidation1">تایید قوانین</label>

</div>

</div>

</div> -->

                    <div class="mt-5 mb-5 text-center">
                        <button type="submit" class="min-w150 tgju-btn tgju-btn-lg tgju-btn-primary" @click="save()" style="padding: 9px 15px 13px;"><i class="uil uil-save"></i> بروزرسانی</button>
                    </div>
                    <!-- <div class="form-line-title mb-0 mt-4">

<div class="form-line-title-block">

<i class="uil uil-bell" aria-hidden="true"></i>

<span class="title">ربات های اطلاع رسانی</span>

</div>

<div class="float-left">

<div class="badge badge-success p-1" @click="addMessengerModal()">

<i aria-hidden="true" class="uil uil-plus"></i> 

افزودن 

</div>

</div>

</div>

<div class="form-group pading-content-row" style="padding: 0 10px;margin-bottom: 0;">

<div class="bank-items">

<div class="bank-items-list">

<div class="form-row">

<table v-if="messengers.length" class="notification-tbl messengers-tpl">

<thead>

<tr>

<th> پیام رسان </th>

<th> وضعیت </th>

<th></th>

</tr>

</thead>

<tbody >

<tr v-for="item in messengers" :key="item.id">

<td>

<span class="pr-2" style="font-size: 13px;font-weight: 600;">{{ item.type }}</span>

</td>

<td class="pr-2">

<span v-if="item.status == 'published'" class="badge badge-pill badge-success status-badge" style="font-size: 12px;">

فعال

</span>

<span v-else class="badge badge-pill badge-danger status-badge" style="font-size: 12px;">

غیرفعال

</span>

</td>

<td>

<button v-if="item.status == 'published'" type="button" class="badge badge-pill badge-danger" @click="disableMessenger(item.id)">

حذف

</button>

</td>

</tr>

</tbody>

</table>

<div class="col-xs-1 center-block w-100" v-else>

<div class="popup-dash-empty">

<i class="uil uil-exclamation" aria-hidden="true"></i>

<span>پیام رسان فعالی موجود نیست</span>

</div>

</div>

</div>

</div>

</div>

</div> -->
                </form>
            </div>
            <div class="stocks-block-icon"><i class="uil uil-setting"></i></div>
        </div>
    </div>
</div>
</template>

<style lang="scss" scoped>
form i {
    margin-left: 5px;
}
</style>

<script>
// این وویو برای ویرایش پروفایل کاربر می باشد
import data_picker from 'vue-persian-datetime-picker';
import VueTextareaAutosize from "vue-textarea-autosize";
Vue.use(VueTextareaAutosize);

export default {
    name: 'ProfileEditView',
    components: {
        'data-picker': data_picker,
    },
    data: function () {
        return {
            max: 12,
            profile: {
                firstname: undefined,
                lastname: undefined,
                email: undefined,
                mobile: undefined,
                opts: {
                    birthdate: undefined,
                    address: undefined,
                    country: undefined,
                    province: undefined,
                    postalCode: undefined,
                    social: {
                        telegram: undefined,
                        instagram: undefined,
                        facebook: undefined,
                        linkedin: undefined,
                        twitter: undefined,
                        whatsapp: undefined,
                        discord: undefined,
                    },
                    terms: false,
                }
            },
            messengers: {},
            birthdate: undefined,
            countries: {},
            provinces: {},
        }
    },
    mounted() {
        // در هنگام لود شدن صفحه این تابع اجرا می شود
        this.getUser();
        this.getCountries();
    },
    methods: {
        // این متد برای ذخیره سازی اطلاعات پروفایل استفاده میشود
        save() {
            // تعریف ارایه ی از ارور های احتمالی که از سمت سرور برگشت داده می شود
            let server_response = {
                profile_field_empty: 'فیلد های بخش پروفایل الزامی است',
                address_field_empty: 'فیلد های بخش آدرس الزامی است',
                terms_shall_checked: 'قوانین را باید بپذیرید',
                profile_field_to_long: 'کاراکتر وارده شده بیش از حد مجاز می‌باشد',
            };

            this.profile.opts.birthdate = this.birthdate;
            let api_data = {
                data: JSON.stringify(this.profile)
            };
            // ارسال درخواست به سرور
            this.$helpers.makeRequest('POST', '/user/edit', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    // در صورتی که عملیات موفق بود پیام موفقیت به نمایش در آید
                    this.$toast.success({
                        title: 'موفق',
                        message: 'ویرایش پروفایل با موفقیت ثبت شد'
                    });

                    // داده های ویو استور را آپدیت میکنیم با داده های جدید
                    this.$store.dispatch('setUserData', { forced: true });
                    this.$router.push({ name: 'Profile' });
                }

                //  در غیر این صورت پیام خطا را نمایش می دهیم
                if (api_response.status == 400) {
                    this.$toast.error({
                        title: 'خطا',
                        message: server_response[api_response.data.response.message]
                    });
                }
            });
        },
        // این متد برای دریافت استان ها به کار میرود
        getCountries() {
            let api_data = {};
            // ارسال درخواست ای پی آی
            this.$helpers.makeRequest('GET', '/user/countries', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);
                // در صورتی که درخواست موفق بود
                if (api_response.data.response.status === 'success') {
                    this.countries = api_response.data.response.detail;
                }
            });
        },
        // این متد برای دریافت شهر های مرتبط با استان مورد نظر به کار میرود
        getProvinces(auto) {
            if (auto != 'auto') {
                this.profile.opts.province = undefined;
            }

            let api_data = {
                country_id: this.profile.opts.country,
            };

            // ارسال درخواست ای پی آی
            this.$helpers.makeRequest('GET', '/user/provinces', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);
                // در صورتی در خواست ای پی آی پیام موفقیت داشت
                if (api_response.data.response.status === 'success') {
                    this.provinces = api_response.data.response.detail;
                }
            });
        },
        // این متد برای دریافت اطلاعات کاربر جاری را به کار می رود
        getUser() {
            let api_data = {};
            // ارسال درخواست ای پی آی
            this.$helpers.makeRequest('GET', '/user/profile-edit', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);
                // در صورتی در خواست ای پی آی پیام موفقیت داشت
                if (api_response.data.response.status === 'success') {
                    this.messengers = api_response.data.response.detail.messengers;
                    this.profile = api_response.data.response.detail.user;
                    // تبدیل به آبجکت جهت دسترسی بهتر
                    this.profile.opts = this.$helpers.json_pars(this.profile.opts);
                    this.birthdate = this.profile.opts.birthdate;

                    if (this.profile.opts.country > 0) {
                        this.getProvinces('auto');
                    }
                }

            });
        },
        // این متد جهت باز کردن مودال مدیریت مسنجر هایی که به اکانت کار متصل هستند مورد استفاده قرار میگیرد . 
        addMessengerModal() {
            let callbackEvent = this.$router.currentRoute.name + '_MessengerAdd_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'MessengerAdd.vue',
                config: {
                    title: 'افزودن پیام رسان',
                    smallModal: true,
                    data: {},
                }
            };

            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (response) => {
                if (response.action == 'AddMessenger') {
                    let messenger = response.data.messenger;
                    // ارسال درخواست ای پی آی
                    this.$helpers.makeRequest('POST', '/user/generate-messenger-token/' + messenger.type).then(api_response => {
                        this.$helpers.api_alert(api_response);
                        // در صورتی در خواست ای پی آی پیام موفقیت داشت
                        if (api_response.status == 200) {
                            let callbackEvent = this.$router.currentRoute.name + '_MessengerToken_' + new Date().getTime();

                            let modal_data = {
                                modal: 'general_modal',
                                callbackEvent,
                                component: 'MessengerToken.vue',
                                config: {
                                    title: 'فعال سازی پیام رسان',
                                    smallModal: true,
                                    data: {
                                        link: messenger.link,
                                        token: api_response.data.token
                                    },
                                }
                            };

                            this.$root.$emit('open_modal', modal_data);
                        }
                    });
                }
            });
        },
        disableMessenger(id) {
            this.$helpers.confirm('هشدار قطع اتصال', {
                message: 'آیا از قطع اتصال اطمینان دارید ؟',
            }).then((confirmed) => {
                if (confirmed) {
                    let api_data = { id: id };
                    // ارسال درخواست ای پی آی
                    this.$helpers.makeRequest('POST', '/user/disable-messenger', api_data).then(api_response => {
                        this.$helpers.api_alert(api_response);
                        // در صورتی در خواست ای پی آی پیام موفقیت داشت
                        if (api_response.data.status) {
                            Vue.prototype.$toast.success({
                                title: 'موفق',
                                message: 'با موفقیت اتصال قطع شد',
                            });
                            // بروز رسانی لیست مسنجر ها و اطلاعات کاربر
                            this.getUser();
                        }
                    });
                }
            });
        },
    },
}
</script>
